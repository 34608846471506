<template>
	<div class="main__container main__padding" v-if="!token">
		<div class="loginBox column__center">
			<LogoSvg />
			<h1 class="text__semibold mt-25 mb-40">Registrieren</h1>
			<form style="width:320px">
				<div
					class="searchbox row__start mb-25"
					:class="
						userMailValid === null
							? ''
							: userMailValid
							? 'searchbox--green'
							: 'searchbox--red'
					"
				>
					<input
						type="text"
						v-model="userMail"
						placeholder="Email"
						@keyup="checkEmail"
					/>
					<p class="error__message" v-if="userError">
						{{ userError }}
					</p>
				</div>

				<div
					class="searchbox row__start mb-25"
					:class="
						passwordValid === null
							? ''
							: passwordValid
							? 'searchbox--green'
							: 'searchbox--red'
					"
				>
					<input
						:type="seePassword ? 'text' : 'password'"
						v-model="password"
						placeholder="Passwort"
						autocomplete
						@keyup="checkPasswordStrength"
					/>
					<div @click="seePassword = !seePassword">
						<SeeSvg
							:classes="
								seePassword ? 'svg__fill--primary' : 'svg__fill--grey700'
							"
						/>
					</div>
					<p class="error__message" v-if="passwordError">
						{{ passwordError }}
					</p>
				</div>
				<div
					class="searchbox row__start mb-35"
					:class="
						confirmPasswordValid === null
							? ''
							: confirmPasswordValid
							? 'searchbox--green'
							: 'searchbox--red'
					"
				>
					<input
						:type="seeConfirmPassword ? 'text' : 'password'"
						v-model="confirmPassword"
						placeholder="Passwort bestätigen"
						autocomplete
						@keyup="checkPasswordStrength"
					/>
					<div @click="seeConfirmPassword = !seeConfirmPassword">
						<SeeSvg
							:classes="
								seeConfirmPassword ? 'svg__fill--primary' : 'svg__fill--grey700'
							"
						/>
					</div>
					<p class="error__message" v-if="confirmPasswordError">
						{{ confirmPasswordError }}
					</p>
				</div>
			</form>

			<div class="vereinbarung">
				<p style="padding-bottom: 20px; font-size: 13px">
					Die IHK Ostwestfalen zu Bielefeld, die Handwerkskammer Ostwestfalen
					Lippe zu Bielefeld und die IHK Lippe zu Detmold nutzen und verarbeiten
					Ihre im Rahmen dieser Anmeldung angegebenen Kontaktdaten
					ausschließlich für den Zweck des Azubi-Speed-Dating. Zu diesem Zweck
					dürfen Ihre Kontaktdaten an die Betriebe weitergeleitet werden.
				</p>
				<div class="row__start">
					<input
						type="checkbox"
						:active="done"
						@click="done = !done"
						style="cursor: pointer"
					/>
					<div class="header-small" style="margin-left: 10px">
						Hiermit erkläre ich mich ausdrücklich einverstanden.
					</div>
				</div>
				<p style="padding-top: 20px; font-size: 13px">
					Ich kann meine Einwilligung jederzeit mit Wirkung für die Zukunft per
					E-Mail
					<a href="mailto:info@ostwestfalen.ihk.de">info@ostwestfalen.ihk.de</a>
					widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung
					erfolgten Datenverarbeitung berührt wird. Nach Widerruf werden meine
					Kontaktdaten nicht mehr zur Versendung von Informationen in dem oben
					bezeichneten Bereich genutzt.
				</p>
				<br />
			</div>

			<button
				class="button"
				:class="
					!userMailValid || !passwordValid || !confirmPasswordValid || !done
						? 'button--grey2'
						: ''
				"
				@click="checkRegistration"
			>
				<p class="text__white">Registrieren</p>
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "Register",
	data() {
		return {
			userMail: "",
			userMailValid: null,

			password: "",
			seePassword: false,
			passwordValid: null,

			confirmPassword: "",
			seeConfirmPassword: false,
			confirmPasswordValid: null,

			fillTextArray: [],
			userError: "",
			passwordError: "",
			confirmPasswordError: "",

			isLoading: false,
			done: false,
		};
	},
	methods: {
		...mapActions(["registerUser"]),
		async checkRegistration() {
			let target = event.target;
			if (target.classList.contains("button")) {
				target = target.childNodes[0];
			}
			if (
				!this.userMailValid ||
				!this.passwordValid ||
				!this.confirmPasswordValid ||
				this.isLoading
			) {
				return;
			}
			this.isLoading = true;
			target.innerHTML = "<div class='loading'></div>";
			const bool = await this.registerUser({
				mail: this.userMail,
				password: this.password,
				confirmPassword: this.confirmPassword,
			});
			if (bool === true) {
				setTimeout(() => {
					target.innerHTML = "Registrieren";
					this.isLoading = false;
					this.$router.push({
						name: "Home",
					});
				}, 500);
			} else if (bool) {
				this.userError = bool;
				target.innerHTML = "Anmelden";
				this.isLoading = false;
			} else {
				this.userError = "Bitte später probieren.";
				target.innerHTML = "Anmelden";
				this.isLoading = false;
			}
		},
		checkPasswordStrength() {
			const password = this.password;
			this.passwordError = "";
			this.confirmPasswordError = "";
			this.passwordValid = false;
			if (!/[A-Z]/.test(password)) {
				this.passwordError = "Mindestens ein Großbuchstaben.";
				return;
			}
			if (!/[a-z]/.test(password)) {
				this.passwordError = "Mindestens ein Kleinbuchstaben.";
				return;
			}
			if (!/[0-9]/.test(password)) {
				this.passwordError = "Mindestens eine Zahl.";
				return;
			}
			if (!/[^A-Za-z0-9]/.test(password)) {
				this.passwordError = "Mindestens ein Sonderzeichen.";
				return;
			}
			if (password.length <= 5) {
				this.passwordError = "Mindestens 6 Zeichen.";
				return;
			}
			this.passwordValid = true;
			this.confirmPasswordValid = false;

			if (this.password === this.confirmPassword) {
				this.confirmPasswordValid = true;
			} else {
				this.confirmPasswordError = "Passwörter stimmen nicht überein.";
			}
		},
		checkEmail() {
			this.userError = "";
			const checkedMail = this.userMail.split(" ").join("");
			const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			this.userMailValid = re.test(String(checkedMail).toLowerCase());
			if (this.userMailValid) {
				this.checkPasswordStrength();
			} else {
				this.userError = "Dies ist keine gültige E-Mail.";
			}
		},
	},
	created() {
		if (this.token) {
			this.$router.push({
				name: "Home",
			});
		}
	},
	computed: {
		...mapGetters(["token"]),
	},
	mounted() {},
};
</script>

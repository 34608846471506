<template>
  <div class="main__container main__padding column__center">
    <div class="imprint-box">
      <h2>Anleitung zur Buchung von Gesprächsterminen</h2>
      <br>
      <div>
        <div class="header-3">
          Wähle ein Unternehmen aus, bei dem Du den Termin buchen möchtest
        </div>
        <p>
          Mögliche Unternehmen und deren Angebote werden Dir durch Deine Suche
          auf der Startseite angezeigt. Klicke auf das Unternehmen, bei dem Du
          den Termin buchen möchtest.
        </p>
      </div>
      <br>
      <div>
        <div class="header-3">Gesprächsart aussuchen</div>
        <p>
          Dir wird angezeigt, wie Du mit dem Unternehmen in Kontakt treten
          kannst. Bei einer Auswahlmöglichkeit wähle bitte Deine gewünschte
          Gesprächsart aus und bestätige Deine Wahl mit dem Button „Weiter“.
        </p>
        <br />
        <p>
          <a style="font-weight: bold">Telefon</a>: Der Klassiker – Das
          Telefonat. Möchtest Du mit dem Unternehmen ein Telefongespräch führen,
          wähle diese Option aus. Bei der Gesprächsart „Telefon“ bekommst Du
          keinen Link, sondern die Telefonnummer gesendet, die Du zum
          vereinbarten Termin anrufen musst. Das Unternehmen hat keinerlei Daten
          über Dich, daher kann es Dich auch nicht erreichen.
        </p>
        <br />
        <p>
          <a style="font-weight: bold">Chat</a>: Willst du lieber mit dem
          Unternehmen chatten oder schreiben? Dann wähle diese Möglichkeit aus.
          Tipp: Achte hierbei insbesondere auf deine Rechtschreibung sowie
          Formulierung.
        </p>
        <br />
        <p>
          <a style="font-weight: bold">Video</a>: Video-Chat/Videokonferenz über
          Jits
        </p>
        <br />
        <p>
          Hinweise: Die Gespräche per Videotelefonat/Video-Chat werden über die
          Software Jitsi geführt, wobei Du selbst keine Software installieren
          musst. Der Link zum Video-Gespräch wird im Browser geöffnet.
        </p>
        <br />
        <p>
          Dabei ist folgendes unbedingt zu beachten: Beim Videogespräch über
          Jitsi kannst Du die Video- und Tonfunktion bei einigen Browsern nur
          dann nutzen, wenn Du die aktuelle Version auf Deinem Endgerät
          (Smartphone, Tablet , Laptop, etc.) heruntergeladen hast. Dies gilt
          zum Beispiel für die Browser Google Chrome, Opera und Safari. Mit
          anderen Browsern, zum Beispiel Firefox oder dem Internet Explorer,
          funktioniert die Video- und Tonfunktion nicht bzw. nur schlecht.
        </p>
        <br />
        <p>
          Weitere Tipps zum Videogespräch findest Du in unserem kurzen Video.
          (Verlinkung IHK-Video zu Vorstellungsgesprächen)
        </p>
      </div>
      <br>
      <div>
        <div class="header-3">Ausbildugsstelle auswählen</div>
        <p>
          Wähle Deine gewünschte Ausbildungsstelle aus den Angeboten des
          Unternehmens aus.
        </p>
        <br />
        <p>
          Solltest Du Dir noch nicht sicher sein, welcher Beruf für Dich in
          Frage kommt, kannst Du auch für unterschiedliche Ausbildungsstellen
          einzelne Termine vereinbaren. Hierzu musst Du den Buchungsvorgang pro
          Ausbildungsstelle wiederholen.
        </p>
        <br />
        <p>Bestätige Deine Auswahl mit dem Button „Weiter“.</p>
      </div>
      <br>
      <div>
        <div class="header-3">Terminauswahl</div>
        <p>Bitte wähle nun den Tag Deines gewünschten Termins aus.</p>
        <p>Bestätige Deine Auswahl mit dem Button „Weiter“.</p>
      </div>
      <br>
      <div>
        <div class="header-3">Uhrzeit wählen</div>
        <p>
          Bitte wähle aus den vorgegebenen Uhrzeiten Deine Wunschuhrzeit für den
          Termin aus.
        </p>
        <br />
        <p>
          <a style="font-weight:bold;">WICHTIG</a>: Wähle nur einen Termin aus, der frühestens am folgenden Tag
          stattfindet. Ist es schon nach 15:00 Uhr, wähle nur einen Termin, der
          frühestens am übernächsten Tag stattfinden wird. So kannst Du Dich
          richtig vorbereiten und die Unternehmen sich auch.
        </p>
        <br />
        <p>Bestätige Deine Auswahl mit dem Button „Weiter“.</p>
      </div>
      <br>
      <div>
        <div class="header-3">Melde Dich an</div>
        <p>Du hast alles ausgewählt!</p>
        <br />
        <p>
          Um die Daten zu speichern musst Du Dich nun mit Deiner E-Mail-Adresse
          sowie Deinem Passwort, welches Du bei der Registrierung generiert
          hast, anmelden.
        </p>
        <br />
        <p>
          Du bist noch nicht registriert? Dann drücke auf den Button
          „Registrieren“ und hole dies schnellstmöglich nach!
        </p>
        <br />
        <p>
          An diese E-Mail-Adresse erhältst Du auch die Benachrichtigungen zu
          Deinen gebuchten Terminen.
        </p>
        <br />
        <p>
          Deine Anmeldung hat funktioniert? Super, bestätige Deine Auswahl mit
          „Weiter“.
        </p>
      </div>
      <br>
      <div>
        <div class="header-3">Bestätige den Termin</div>
        <p>
          Bitte kontrolliere alle Angaben der Terminbuchung. Ist alles richtig?
          Dann bestätige die Buchung über den Button „Absenden“.
        </p>
      </div>
      <br>
      <div>
        <div class="header-3">Dein Termin ist gebucht!</div>
        <p>
          Bitte notiere Dir den Termin in Deinem Kalender, sodass Du pünktlich
          bei dem Unternehmen anrufst.
        </p>
        <p>
          Du hast nun eine E-Mail mit der Bestätigung der Terminbuchung, sowie
          der Telefonnummer/Link zum Gespräch, erhalten.
        </p>
        <p>
          TIPP: Falls Du die Bestätigungs-E-Mail nicht gleich im Posteingang
          hast, schau auch mal in Deinem SPAM-Ordner nach.
        </p>
        <p>
          Deine(n) gebuchten Termin(e) kannst Du Dir jederzeit auf der Website
          bzw. App unter „Meine Termine“ anzeigen lassen.
        </p>
      </div>
      <br>
      <div>
        <div class="header-3">Stornieren eines Termins</div>
        <p>
          Wir bitten Dich, die gebuchten Termine auch tatsächlich wahrzunehmen.
          Solltest Du einmal einen Termin nicht wahrnehmen können, z. B. weil Du
          krank bist, storniere den Termin rechtzeitig, damit das Unternehmen
          Bescheid weiß und nicht vergeblich auf Dich wartet. In der Übersicht
          Deiner Terminbuchungen findest Du eine entsprechende Funktion
          „Stornieren“.
        </p>
      </div>
      <br>
      <div class="row__start mt-20">
        <router-link
          :to="{ name: 'TippsAndTricks' }"
          class="button button--reverse button--big mr-20"
        >
          <p class="text__primary">Tipps & Tricks</p>
        </router-link>
      </div>
      <Footer class="w-100" />
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  components: { Footer },
  name: "ForStudents",
  data() {
    return {};
  },
  methods: {},
  created() {},
  computed: {},
};
</script>

<template>
	<div class="main__container main__padding">
		<div class="row__spacebetween row__spacebetween--wrap">
			<h2>Finde Deinen Berufseinstieg in OWL</h2>

			<CooperationHomeSvg class="mt-10 mb-15" />
		</div>
		<div class="row__start row__start--stretch dashboard__row">
			<div class="row__start--flex1">
				<home-filter-companies
					:filter="filter"
					:count="filteredCompanies.length"
				/>
			</div>
			<div class="row__start--flex3">
				<HomeGoogleMaps :show="showMap" />
				<div class="responsive--show">
					<div
						class="row__start mt-15"
						@click.stop="showMap = !showMap"
					>
						<GoogleSvg />
						<p class="text__primary ml-8">
							{{
								showMap
									? "Kartenansicht entfernen"
									: "Kartenansicht"
							}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="primary__line"></div>
		<HomeFilter :filter="filter" class="mb-50" />
		<div class="row__spacebetween">
			<h2>Hier findest Du Deine Suchergebnisse:</h2>
			<div style="display: flex; position:relative;">
				<p class="text__grey company_counter" style="margin-top: 35px;">
					Anzahl: {{ filteredCompanies.length }}
				</p>
				<general-select
					class="mt-15"
					style="width: 132px; margin-right: 18px; position:absolute; right:0px;"
					classes="selectbox--big"
					placeholder="Sortierung"
					:options="occupationOptions"
					:selectedItem="sorting"
					@change="
						(option) => {
							if (sorting.id == option.id) {
								sorting = {};
							} else {
								sorting = option;
							}
						}
					"
				/>
			</div>
		</div>

		<HomeCompanyResults :filteredCompanies="filteredCompanies" />
		<Footer />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import Debouncer from "@/common/debouncer";
	export default {
		components: {},
		name: "Home",
		data() {
			return {
				showMap: false,
				filter: {
					selectedOccupation: {},
					selectedApprenticeship: {},
					selectedOffer: {},
					selectedDistricts: [],
					selectedPlace: null,
					selectedCompany: {},
					companyName: null,
					selectedYear: null,
				},
				sorting: {},
				debouncer: new Debouncer(),
				occupationOptions: [
					{ id: 1, name: "A-Z" },
					{ id: 2, name: "Z-A" },
				],
			};
		},
		methods: {
			...mapActions(["getDashboardOptions", "getAvailableCompanies"]),
			changeFilter() {
				this.getAvailableCompanies({
					occupationId: this.filter.selectedOccupation.id,
					offerId: this.filter.selectedOffer.id,
					filterText: this.filter.companyName,

					districts: this.filter.selectedDistricts,
					companyId: this.filter.selectedCompany.id,
					apprenticeshipId: this.filter.selectedApprenticeship.id,
					city: this.filter.selectedPlace,
					year: this.filter.selectedYear,
				});
			},
			changeEvent() {
				this.debouncer.debounce(this.getAvailableCompanies, 5000);
			},
		},
		async mounted() {
			// await this.getDashboardOptions();

			// this.filter.selectedDistricts = this.districtOptions;

			if (sessionStorage.filter) {
				var stringifyedObject = sessionStorage.filter;
				// Parse String back to Object
				this.filter = JSON.parse(stringifyedObject);
			}
			else 
				this.getAvailableCompanies({});
		},
		computed: {
			...mapGetters(["availableCompanies", "districtOptions"]),
			filteredCompanies() {
				let companies = [];
				let index = 0;
				this.availableCompanies.forEach((company) => {
					company.apprenticeships.forEach((apprenticeship) => {
						companies.push({
							id: company.id,
							index,
							allLatLongs: company.allLatLongs,
							allAddresses: company.allAddresses,
							apprenticeship: apprenticeship,
							city: apprenticeship.city,
							occupations: apprenticeship.occupations,
							year: apprenticeship.year,
							name: company.name,
						});
						index++;
					});
				});
				if (this.sorting.id == 1) {
					return companies.sort(function(a, b) {
						if (
							a.apprenticeship.name.toLowerCase() <
							b.apprenticeship.name.toLowerCase()
						) {
							return -1;
						}
						if (
							a.apprenticeship.name.toLowerCase() >
							b.apprenticeship.name.toLowerCase()
						) {
							return 1;
						}
						return 0;
					});
				} else if (this.sorting.id == 2) {
					return companies.sort(function(a, b) {
						if (
							a.apprenticeship.name.toLowerCase() >
							b.apprenticeship.name.toLowerCase()
						) {
							return -1;
						}
						if (
							a.apprenticeship.name.toLowerCase() <
							b.apprenticeship.name.toLowerCase()
						) {
							return 1;
						}
						return 0;
					});
				}
				return companies.sort(() => 0.5 - Math.random());
			},
		},
		watch: {
			filter: {
				handler: function() {
					// this.changeFilter();
					this.debouncer.debounce(this.changeFilter);
					// Convert Object to String in order to save it in session Storage -> Storage can't handle objects
					sessionStorage.filter = JSON.stringify(this.filter);
				},
				deep: true,
			},
		},
	};
</script>
<style lang="scss" scoped>
	.cooperation--image {
		height: 36px;
	}
	.company_counter {
		margin-right: 200px;
	}
	@media only screen and (max-width: 768px) {
		.company_counter {
			margin-right: 43vw;
		}
	}
</style>

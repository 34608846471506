<template>
	<div class="main__container main__padding" v-if="token">
		<h2>Meine Termine</h2>
		<p class="mb-25">
			Hier hast Du eine Übersicht deiner gebuchten Termine.
		</p>
		<div class="row__start row__start--wrap mb-60">
			<CalendarAppointment
				v-for="appointment in myAppointments"
				:key="appointment.id"
				:appointment="appointment"
				@openModal="showModal"
			/>
		</div>
		<div class="modal__smallLayout" v-if="modalOpen && modalType == 'info'">
			<div
				class="modal__small column__center"
				v-click-outside="modalFunction"
			>
				<p class="mt-25 mb-50 text__center">
					Rufe zur vereinbarten Zeit<br />
					das Unternehmen selbst an.
				</p>
				<div class="row__center mb-25">
					<div
						class="button button--reverse"
						@click="modalOpen = false"
					>
						<p class="text__primary text__semibold">Verstanden</p>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal__smallLayout"
			v-else-if="modalOpen && modalType == 'delete'"
		>
			<div
				class="modal__small column__center"
				v-click-outside="modalFunction"
			>
				<p class="mt-25 mb-50">Den Termin verbindlich stornieren?</p>
				<div class="row__center mb-25">
					<div
						class="button button--reverse mr-25"
						@click="modalOpen = false"
					>
						<p class="text__primary text__semibold">Abbrechen</p>
					</div>
					<div class="button" @click.stop="prepareDeleteAppointment">
						<p class="text__white">Stornieren</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import ClickOutside from "vue-click-outside";
	export default {
		name: "Calendar",
		data() {
			return {
				item: { id: null, name: "" },
				modalOpen: false,
				modalType: null,
				selectedAppointment: null,
				isLoading: false,
			};
		},
		methods: {
			...mapActions(["getMyAppointments", "deleteAppointment"]),
			showModal(appointment, modalType) {
				this.selectedAppointment = appointment;
				this.modalOpen = true;
				this.modalType = modalType;
			},
			modalFunction() {
				this.modalOpen = false;
				this.modalType = null;
			},
			async prepareDeleteAppointment() {
				if (!this.selectedAppointment || this.isLoading) {
					return;
				}
				this.isLoading = true;
				let target = event.target;
				if (target.classList.contains("button")) {
					target = target.childNodes[0];
				}
				target.innerHTML = "<div class='loading'></div>";
				const appointmentId = this.selectedAppointment.id;
				const succeeded = await this.deleteAppointment({
					appointmentId,
				});
				if (succeeded === true) {
					let filteredAppointments = this.myAppointments.filter(
						function(oneAppointment) {
							if (appointmentId != oneAppointment.id) {
								return oneAppointment;
							}
						}
					);
					this.$store.commit(
						"setMyAppointments",
						filteredAppointments
					);
					setTimeout(() => {
						this.modalFunction();
						this.isLoading = false;
					}, 500);
				}
			},
		},
		created() {
			if (!this.token) {
				this.$router.push({
					name: "Login",
				});
			}
			this.getMyAppointments();
		},
		computed: {
			...mapGetters(["token", "myAppointments"]),
		},
		directives: {
			ClickOutside,
		},
	};
</script>
